import React from 'react'
import PT from 'prop-types'

const Picture = ({ className, pic }) => {
    return (
        <picture className={className}>
            {pic.source.map(({ srcSet, retina, media }, i) => (
                <source key={i} srcSet={`${srcSet} 1x, ${retina} 2x`} media={media} />
            ))}
            <img src={pic.src} srcSet={`${pic.retina} 2x`} alt={pic.alt} />
        </picture>
    )
}

export default Picture

Picture.propTypes = {
    pic: PT.object.isRequired,
    className: PT.string,
}
