import React from 'react'
import classNames from 'classnames'
import PT from 'prop-types'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Arrow from 'src/components/SvgSprite/ArrowIcon'

import styles from './styles.module.scss'

const LetsTalk = ({ className, brief, onClick = () => {} }) => {
    const sectionContainerClass = classNames('container', styles.letsTalk, className)
    const contactUsBtnClassName = classNames('btn', styles.contactBtn)

    const clickHandler = () => {
        onClick()
    }

    return (
        <section className={sectionContainerClass}>
            <h3 className={styles.title}>Get In Touch About Your Project</h3>
            <div className={styles.btns}>
                <div className={styles.contactBtnContainer} onClick={clickHandler}>
                    <AnchorLink className={contactUsBtnClassName} to="/#contact">
                        Contact us
                    </AnchorLink>
                </div>
                {brief && (
                    <a className={styles.download} href={brief.file} download={brief.name}>
                        <Arrow className={styles.arrow}>
                            <span>Download Brief</span>
                        </Arrow>
                    </a>
                )}
            </div>
        </section>
    )
}

LetsTalk.propTypes = {
    className: PT.string,
    brief: PT.object,
    onClick: PT.func,
}

export default LetsTalk
