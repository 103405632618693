import React, { Component } from 'react'
import PT from 'prop-types'
import SvgSpriteIcon from './SvgSpriteIcon.js'

class Arrow extends Component {
    static propTypes = {
        children: PT.element,
        className: PT.string,
        disabled: PT.bool,
        arrow: PT.object,
        onClick: PT.func,
    }

    state = {
        hover: false,
    }

    toggleHover = () => {
        this.setState({ hover: !this.state.hover })
    }

    render() {
        const { children, className, disabled = false, arrow = false, onClick } = this.props
        return (
            <span onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} onClick={onClick}>
                {children}
                {(arrow && (
                    <SvgSpriteIcon
                        name={
                            disabled ? arrow.disabled : this.state.hover ? arrow.hover : arrow.usual
                        }
                        className={className}
                    />
                )) || (
                    <SvgSpriteIcon
                        name={
                            disabled ? 'arrowDisabled' : this.state.hover ? 'arrowHover' : 'arrow'
                        }
                        className={className}
                    />
                )}
            </span>
        )
    }
}

export default Arrow
